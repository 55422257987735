<div
  class="qr-card-info-office__container flex-col flex-start-center"
  itemscope
  itemtype="https://schema.org/LocalBusiness">
  @if (this.data) {
    @if (!isImgTop) {
      <div class="w-100 container-component flex-row">
        <!-- Modo de detalle de propiedad (Foto de perfil al costado)  -->
        <div class="photo-container flex-row flex-center-center">
          <picture-source
            [config]="officesResizedImages"
            [src]="resizedImageWebp"
            [alt]="'Foto'"></picture-source>
        </div>
        <div fxFlex class="info-container">
          <div class="title">
            <h2>
              {{
                'office-detail.office' | translate: locale.language | uppercase
              }}
            </h2>
          </div>
          <div class="name">
            <h3 itemprop="name">{{ data.name }}</h3>
          </div>
          <div class="license">
            <p itemprop="address">{{ data.address }}</p>
          </div>
        </div>
      </div>
    } @else {
      <div class="office-container flex-col flex-center-center" id="top">
        <!-- Perfil de oficina  -->
        <div class="photo-container-top flex-center-center">
          <picture-source
            [config]="officesResizedImages"
            [src]="resizedImageWebp"
            [alt]="'Foto'"></picture-source>
        </div>
        <div id="top" class="info-container">
          <div id="office-top">
            <p>
              {{
                'officeDetail.office' | translate: locale.language | uppercase
              }}
            </p>
          </div>
          <div id="name-top">
            <h4 itemprop="name">{{ data.name }}</h4>
          </div>
          <div id="address-top">
            <h4 itemprop="address">{{ data.address }}</h4>
          </div>
        </div>
      </div>
      <!-- CIERRO CONTENEDOR IMG TOP  -->
      <div
        class="contact-container container-component flex-col flex-center-start">
        <!-- Celular -->
        @for (phone of this.data.phones; track phone) {
          <div>
            @if (phone.type === 'work') {
              <div class="contact-item flex-center-center">
                <div id="icon-phone" class="icon"></div>
                <div itemprop="telephone">
                  <a href="tel:{{ phone.value }}">{{ phone.value }}</a>
                </div>
              </div>
            } @else {
              @if (phone.type === 'mobile') {
                <div class="contact-item flex-center-center">
                  <div id="icon-phone" class="icon"></div>
                  <div itemprop="telephone">
                    <a href="tel:{{ phone.value }}">{{ phone.value }}</a>
                  </div>
                </div>
              } @else {
                <div class="empty"></div>
              }
            }
          </div>
        }
        @for (email of this.data.emails; track email) {
          <div class="contact-item flex-center-center">
            <div id="icon-email" class="icon"></div>
            <div itemprop="email">
              <a href="mailto:{{ email.value }}">{{ email.value }}</a>
            </div>
          </div>
        }
      </div>
    }
  }
</div>
